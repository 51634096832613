import { useNavigate } from 'react-router-dom';
import { Routes } from '~/common/constants/routes';

export const useNavigation = () => {
  const navigate = useNavigate();

  return {
    goTo: navigate,
    goToHome: () => navigate(Routes.Home),
    goToCart: () => navigate(Routes.Cart),
    goToShop: () => navigate(Routes.Shop),
    goToTerms: () => navigate(Routes.Terms),
    goToProduct: (handle: string, selectedSize?: string) =>
      navigate(
        `${Routes.Product}/${handle}${
          selectedSize ? `/?selectedSize=${selectedSize}` : ''
        }`,
      ),
  };
};
